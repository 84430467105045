<template>
  <ion-page class="page white"> Logging out... </ion-page>
</template>

<script lang="ts" setup>
import { authStore } from '@/shared/pinia-store/auth';

onMounted(async () => {
  const { logout } = authStore();
  await logout();
});
</script>

<style scoped lang="sass">
.page
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  padding: 20px
  *
    font-size: 0.9rem
</style>
